




















import {
  computed,
  defineComponent, onMounted, PropType,
} from '@nuxtjs/composition-api';
import { SfInput, SfList } from '~/components';
import { useStore } from "../../../../composables/useStore";
import { AvailableStores, useConfig } from "~/composables";

export default defineComponent({
  name: 'LanguageSelector',
  components: {
    SfInput,
    SfList,
  },
  setup() {
    const {
      stores,
      change: changeStore,
      load: loadStores,
    } = useStore();
    const { config } = useConfig();

    const availableStores = computed<AvailableStores>(() => stores.value ?? []);

    onMounted(() => {
      if (stores.value && stores.value?.length) return;
      loadStores();
    });

    return {
      availableStores,
      changeStore,
      storeConfig: config,
    };
  },
});
